<template>
  <div v-if="loggedInUserType === 'superAdmin' || loggedInUserType === 'admin'">
    <img v-if="clicked" style="width: 20px; height: auto;" :src="loaderImage" alt="processing ..." />
    <p
      v-else
      @click="verifyEmail"
      style="font-size: 1rem; cursor: pointer; color: rgba(var(--vs-primary),1)!important;"
    >Verify Now</p>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "VerifyEmail",
  props: {
    userId: {
      type: String,
    },
  },
  data() {
    return {
      loaderImage: require("@/assets/images/loader/loader.gif"),
      clicked: false,
      loggedInUserType: this.$store.state.AppActiveUser.userType,
    };
  },
  methods: {
    ...mapActions("general", ["verifyUserEmailById"]),
    verifyEmail() {
      this.clicked = true;
      this.verifyUserEmailById(this.userId)
        .then((res) => {
          this.$emit("onSuccessfullyVerified", res.data.data.emailVerifiedAt);
        })
        .catch((err) => console.log("####", err));
    },
  },
};
</script>
